import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButtonPS = styled.button`
  font-family:'Mochiy Pop One', sans-serif;
  margin-bottom:5px;
  padding: 10px;
  border-radius: 24px;
  border: none;
  background-color: rgba(1, 127, 23, 1);
  font-weight: bold;
  color: #fff;
  width: 256px;
  cursor: pointer;
  box-shadow: 0px 6px 2px -2px rgba(100, 100, 100, 0.5);
  -webkit-box-shadow: 0px 5px 0px -2px rgba(100, 100, 100, 0.5);
  -moz-box-shadow: 2px 6px 0px -2px rgba(100, 100, 100, 0.5);
  transition: .1s;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  :hover{
    transform: translate3d(0, 2px, 0);
    box-shadow: none;
  }
  }
`;

export const StyledButtonPSClamed = styled.button`
  font-family:'Mochiy Pop One', sans-serif;
  padding: 10px;
  border-radius: 24px;
  border: none;
  background-color: rgb(156, 156, 156);
  font-weight: 600;
  color: var(--secondary-text);
  width: 256px;
  cursor: pointer;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: rgba(1, 127, 23, 1);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(3333);
  const [mintAmount, setMintAmount] = useState(1);
  const [publicMintLimit, setPublicMintLimit] = useState(0);
  const [publicClaimed, setPublicClaimed] = useState(0);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [pubSaleStart, setPubSaleStart] = useState(false);
  
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkPubSaleStart = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicSaleStart(0)
      .call()
      .then((receipt) => {
        setPubSaleStart(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(0)
      .call()
      .then((receipt) => {
        setTotalSupply(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkMaxSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .maxSupply(0)
      .call()
      .then((receipt) => {
        setMaxSupply(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublicMintLimit = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit(0)
      .call()
      .then((receipt) => {
        setPublicMintLimit(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublicClaimed = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .minted(0, blockchain.account)
      .call()
      .then((receipt) => {
        setPublicClaimed(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);

     // ウォレットの残高を取得
     let balance = await blockchain.web3.eth.getBalance(blockchain.account);
     let balanceWei = Number(balance);
 
     if (balanceWei < totalCostWei) {
       // ウォレットの残高が足りない場合、ユーザーに通知
       alert("ウォレットの残高が不足しています。");
       return;
     }
     // ミント関数のガス代を計算
     console.log("mintAmount: " + mintAmount);
     console.log("Cost: ", totalCostWei);
     console.log("Gas limit: ", totalGasLimit);
     let method = await blockchain.smartContract.methods.publicMint(0, mintAmount);
     let gas = await method.estimateGas({
       from: blockchain.account,
       value: totalCostWei,
     });
     console.log("estimateGas: " + gas);
     // 現在のガス代を取得
     let gasPrice = await blockchain.web3.eth.getGasPrice();
     //トランザクションを通りやすくするために少し乗せる
     gasPrice = Math.round(gasPrice * 1.3);
     console.log("gasPrice: " + gasPrice);
    setFeedback(<font color="#000">ミント中です...</font>);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(0, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        // 上で取得したガス代をここで指定
        gasPrice: gasPrice,
        gas: gas + 50000,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(<font color="#000">失敗したので再度試してみてください</font>);
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          <font color="#000">ミント成功しました！</font>
        );
        setClaimingNft(false);
        checkPublicClaimed();
        checkTotalSupply();
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementPublicMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementPublicMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > (publicMintLimit - publicClaimed)) {
      newMintAmount = publicMintLimit - publicClaimed;
    }
    //0より小さい場合は０にする
    if(newMintAmount < 0){
      newMintAmount = 0;
    }
    setMintAmount(newMintAmount);
  };

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkPublicMintLimit();
    checkPublicClaimed();
    checkPubSaleStart();
    checkMaxSupply();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkPublicMintLimit();
    checkPublicClaimed();
    checkPubSaleStart();
    checkMaxSupply();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundColor: "rgba(248, 214, 73, 1)"}}
      >
      <s.Container
        flex={1}
        ai={"center"}
        style={{background: "rgba(0, 0, 0, 0)"}}
      >
      <s.TextDescription style={{ position: 'fixed', width: '100%', fontSize: 18, textAlign: "left", color: "#fff", backgroundColor: "rgba(1, 127, 23, 1)"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledLink style={{ color: "#fff", margin: "6px 0px 0px 16px",fontFamily:"'Mochiy Pop One', sans-serif"}} href="">
            1000km NFTマラソン
          </StyledLink>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: "16px" }}>
            <a href="https://twitter.com/budou_chan" target="_blank" rel="noopener" style={{ marginRight: "10px" }}>
              <img style={{ width: "22px",marginTop:"11px" }} src="/config/images/tw.png" alt="Twitter Icon"></img>
            </a>
            <a href="https://discord.gg/K9Kv7T7MV" target="_blank" rel="noopener" style={{ marginRight: "10px" }}>
              <img style={{ width: "22px",marginTop:"11px" }} src="/config/images/dc.png" alt="Discord Icon"></img>
            </a>
            <a href="https://opensea.io/collection/1000km-run-nft" target="_blank" rel="noopener">
              <img style={{ width: "22px",marginTop:"11px" }} src="/config/images/os.png" alt="OpenSea Icon"></img>
            </a>
          </div>
        </div>
      </s.TextDescription>

      

      <s.Container
        flex={1}
        ai={"center"}
      >

      <s.Container ai={"center"} jc={"center"} fd={"row"} style={{flexWrap: "wrap"}}>
          <img className="logo"
            style={{maxWidth: "90%", width: "460px", margin: "40px 0px 24px"}}
            src="/config/images/b1.png"
            alt="Logo"
          />
      </s.Container>
          

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 36,
                color: "#5BB66B",
                fontFamily:"'Mochiy Pop One', sans-serif"
              }}
            >
              {1257} / {maxSupply}
            </s.TextTitle>

            
            
            {Number(totalSupply) == CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextDescription
                  style={{ fontSize:"22px",textAlign: "center", color: "var(--accent-text)",color:"#000"}}
                >
                  完売御礼!!
                </s.TextDescription>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)",color:"#000"}}
                >
                  次のフェーズをお待ちください
                </s.TextDescription>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)",color:"#000"}}
                >
                  (100Km走破毎に供給量が増加します)
                </s.TextDescription>
                
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    
                    <StyledButtonPS
                      //onClick={(e) => {
                        //e.preventDefault();
                        //dispatch(connect());
                        //getData();
                      //}}
                    >
                      セール終了しました
                    </StyledButtonPS>
                    
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    {/* Publicここから */}
                    {pubSaleStart == true ? (
                        <>
                        {publicClaimed > publicMintLimit - 1 ? (
                          <>
                          <s.SpacerSmall />
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            

                          <StyledButtonPSClamed
                            disabled={1}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {"最大個数ミント済み" }
                          </StyledButtonPSClamed>
                          </s.Container>
                          </>
                        ) : (
                          <>
                          <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementPublicMintAmount();
                                }}
                              >
                                -
                              </StyledRoundButton>
                              <s.SpacerMedium />
                              <s.TextTitle
                                style={{
                                  fontSize:"28px",
                                  textAlign: "center",
                                  color: "#5BB66B",
                                  fontFamily:"'Mochiy Pop One', sans-serif"
                              
                                }}
                              >
                                {mintAmount}
                              </s.TextTitle>
                              <s.SpacerMedium />
                              <StyledRoundButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementPublicMintAmount();
                                }}
                              >
                                +
                              </StyledRoundButton>
                              </s.Container>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButtonPS
                                disabled={claimingNft ? 1 : 0}//claimingNftがtrueなら disabledを表示させる。＝クリックできない
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                              {claimingNft ? "ミント中です..." : "ミントする"}
                              </StyledButtonPS>
                            </s.Container>
                            {publicClaimed > 0 ? (
                                <>
                                </>                              
                            ):(
                            <></>
                            )}
                          </>
                        )}
                        </>
                      ) : (
                        <>
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <s.TextDescription>
                        {"セール開始までお待ちください"}
                        </s.TextDescription>
                        </s.Container>
                        
                        </>
                      )}
                      {/* publicここまで */}
                  </>
                )}
              </>
            )}
            <s.TextDescription style={{fontSize:"20px"}}>
              応援ありがとうございました！
            </s.TextDescription>
            
            <s.Container ai={"center"} jc={"center"} fd={"row"} style={{flexWrap: "wrap"}}>
          <img className="logo"
            style={{maxWidth: "90%", width: "460px",marginTop:"26px"}}
            src="/config/images/b2.png"
            alt="Logo"
          />
      </s.Container>
 
      <s.SpacerMedium />
        </s.Container>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}
export default App;